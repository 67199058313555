/**
 * Schulung.jsx
 *
 * @file Schulungen page template of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql } from '@apollo/client'
import { graphql } from 'gatsby'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import logging from 'loglevel'
import MuiLink from '@mui/material/Link'
import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// internal imports
import { EventsGallery } from '../components/EventsGallery'
import { Link } from '../components/Link'
import { MailtoDialog } from '../components/alerts'
import routes from '../data/routes.json'

/**
 * This component represents the Schulungen pages of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const Schulung = ({ data }) => {
	const logger = logging
	const loggerGQL = logger.getLogger('graphql')

	logger.debug('Schulung template retrieved data:', data)
	const {
		description,
		information,
		type
	} = data.eventType
	logger.debug(`Event type is: ${ type }`)

	const theme = useTheme()

	/** Query to get the current events from the server. */
	const GET_CURRENT_EVENTS = gql`
		query ${ type }CurrentEvents( $page: Int $type: String! ) {
			currentEvents( first: 9 page: $page type: $type ) {
				data {
					creator {
						id
						name
					}
					end
					eventID
					fee
					htmlLink
					id
					initialRegistrationPeriod
					lecturer {
						id
						name
					}
					lecturerFallback
					periodOfRest
					regionalNetworks
					secondDate {
						end
						id
						start
					}
					secondRegistrationPeriod
					start
					status
					type {
						description
						type
					}
					venue {
						city
						id
						location
						name
						osm
					}
				}
				paginatorInfo {
					count
					currentPage
					firstItem
					hasMorePages
					lastItem
					lastPage
					perPage
					total
				}
			}
		}
	`

	/** Query to get all events from the server. */
	const GET_EVENTS = gql`
		query ${ type }Events( $page: Int $type: String! ) {
			eventsWithoutSecondDates(
				first: 9
				page: $page
				sort: [
					{ column: "created_at" order: DESC }
				]
				type: $type
			) {
				data {
					creator {
						id
						name
					}
					end
					eventID
					fee
					htmlLink
					id
					initialRegistrationPeriod
					lecturer {
						id
						name
					}
					lecturerFallback
					periodOfRest
					regionalNetworks
					secondDate {
						end
						id
						start
					}
					secondRegistrationPeriod
					start
					status
					type {
						description
						type
					}
					venue {
						city
						id
						location
						name
						osm
					}
				}
				paginatorInfo {
					count
					currentPage
					firstItem
					hasMorePages
					lastItem
					lastPage
					perPage
					total
				}
			}
		}
	`

	/** Define a state to control the `MailtoDialog` */
	const [ isMailtoDialogOpen, setMailtoDialogOpen ] = useState( false )
	/** Define a state to control the collapse. */
	const [ open, setOpen ] = useState( true )

	return (
		<>
			<GatsbySeo title={ description } />
			{ type === 'workshop' &&
				<>
					<Typography align="center" color="primary" gutterBottom variant="h4">{ description }</Typography>
					{ information && <Typography paragraph>{ information }</Typography> }
					<Grid container spacing={ 2 }>
						<Grid item md={ 8 } xs={ 12 }>
							<Typography paragraph variant="body2">
								In den kompakten Veranstaltungen erhalten Sie neue Impulse für Ihren Unterricht und profitieren von den Erfahrungen anderer.
							</Typography>
							<Typography paragraph variant="body2">
								Sie üben in aller Ruhe, das Debattieren zu unterrichten. Wir zeigen Ihnen neue Übungen für Ihre schulische Praxis.
							</Typography>
							<Typography paragraph variant="body2">
								So fördern Sie die Demokratiefähigkeit und die sprachlichen Kompetenzen Ihrer Schüler:innen noch effektiver!
							</Typography>
							<Typography paragraph variant="body2">
								Laden Sie hier die <MuiLink
								href={ `${ process.env.GATSBY_FILES_PDF_ENDPOINT }/Workshops-Broschuere.pdf` }
								target="_blank"
								underline="hover">Broschüre zu den Workshops <FontAwesomeIcon icon={ faLink } size="sm" /></MuiLink> herunter.
							</Typography>
						</Grid>
						<Grid item md={ 4 } xs={ 12 }>
							<Box sx={{
								height: "100%",
								position: 'relative',
								[`&:before`]: {
									content: '""',
									display: 'block',
									width: 241
								}
							}}>
								<MuiLink
									href={ `${ process.env.GATSBY_FILES_PDF_ENDPOINT }/Workshops-Broschuere.pdf` }
									tabIndex="-1"
									target="_blank"
									underline="hover">
									<StaticImage
										alt="Broschüre zu den Workshops"
										css={{
											height: '100%',
											left: 0,
											position: 'absolute',
											top: 0,
											width: '100%',
										}}
										height={ 500 }
										layout="constrained"
										loading="lazy"
										objectFit="contain"
										quality={ 85 }
										placeholder="blurred"
										src="../assets/images/Workshops-Broschuere.png" />
								</MuiLink>
							</Box>
						</Grid>
					</Grid>
				</>
			}
			{ type !== 'workshop' &&
				<>
					<Typography align="center" color="primary" gutterBottom variant="h4">{ description }</Typography>
					{ information && <Typography paragraph>{ information }</Typography> }
				</>
			}
			<Typography
				color="secondary"
				gutterBottom
				variant="subtitle1">
				Hinweise zur Anmeldung <IconButton onClick={ () => { setOpen( !open ) } } size="large">{ open ? <ExpandLessIcon /> : <ExpandMoreIcon /> }</IconButton>
			</Typography>

			<Collapse in={ open } sx={{ pl: 1 }} timeout="auto" unmountOnExit>
				<Divider />
				<List>
					{ type === 'sprachlerngruppe_basis' &&
						<>
							<ListItem>
								<ListItemText>
									<Typography variant="body2">
										Wenn Ihre Schule bereits Teil des Schulnetzwerks von Jugend debattiert ist, erfolgt die Anmeldung durch die Jugend-debattiert-Koordinatorin bzw. den Jugend-debattiert-Koordinator.
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<Typography variant="body2">
										Wenn Sie nicht an einer Projektschule unterrichten, können Sie sich dennoch anmelden. In diesem Fall geben Sie bitte Ihren Schulnamen in das Mitteilungsfeld ein.
									</Typography>
								</ListItemText>
							</ListItem>
						</>
					}
					{ type !== 'sprachlerngruppe_basis' &&
						<ListItem>
							<ListItemText>
								<Typography variant="body2">
									Die Anmeldung erfolgt durch die Jugend-debattiert-Koordinatorin bzw. den Jugend-debattiert-Koordinator der jeweiligen Projektschule. { (type === 'basis' || type === 'workshop') && `Für jede Veranstaltung sind maximal 16 Anmeldungen möglich.` }
								</Typography>
							</ListItemText>
						</ListItem>
					}
					<ListItem>
						<ListItemText>
							<Typography variant="body2">
								Um { type === 'jury' ? `Personen` : `Kolleginnen und Kollegen` } anmelden zu können, benötigen Sie deren Einverständnis und müssen bestätigen, dass es Ihnen vorliegt. Das ist ein Erfordernis des Datenschutzes, zumal das Anmeldesystem die Daten auf einem Server speichert. Wir stellen Ihnen gerne ein Formular zur Verfügung, das Sie etwas weiter unten auf dieser Seite abrufen können. <strong>Achtung</strong>: Die angemeldeten Personen müssen die <strong>Anmeldung bestätigen</strong> - hierzu erhalten diese automatisch eine E-Mail, in der sie einen Link anklicken müssen. Erst wenn dies geschehen ist, wird die entsprechende Anmeldung berücksichtigt.
							</Typography>
						</ListItemText>
					</ListItem>
					{ type === 'jury' &&
						<ListItem>
							<ListItemText>
								<Typography variant="body2">
									Die Anmeldung zu den Juryschulungen ist <strong>regional begrenzt</strong>. Das bedeutet, dass Teilnehmer:innen, die nicht aus der Region stammen, in der der Veranstaltungsort liegt, in der Regel <strong>nicht berücksichtigt</strong> werden. Stammen Sie aus einer anderen Region als den Genannten, wenden Sie sich bitte bezüglich einer Juryschulung an Ihre:n regionale:n Ansprechpartner:in.
								</Typography>
							</ListItemText>
						</ListItem>
					}
					<ListItem>
						<ListItemText>
							<Typography variant="body2">
								Bitte beachten Sie die Status-Ampel der jeweiligen Veranstaltung: <strong css={{ color: theme.palette.success.main }}>Grün</strong> ("Online-Anmeldung möglich") bedeutet, dass wir die Anmeldungen bis zum Ende der Anmeldefrist <strong>sammeln</strong>. Die angemeldeten { type === 'jury' ? `Personen` : `Lehrkräfte` } erhalten im Anschluss von uns eine Nachricht, ob sie an der gewünschten Veranstaltung teilnehmen können. Bitte geben Sie daher unbedingt die E-Mail-Adressen aller Teilnehmer:innen korrekt an! { (type === 'basis' || type === 'sprachlerngruppe_basis') && `Neue Projektschulen behandeln wir bevorzugt.` }
							</Typography>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText>
							<Typography variant="body2">
								Eine <strong css={{ color: theme.palette.primary.main }}>blaue</strong> Ampel ("Noch anmelden!") bedeutet, dass nur noch einige Plätze frei sind - jetzt werden die Anmeldungen in der <strong>Reihenfolge</strong> des Eingangs aufgenommen, unabhängig davon, ob die Schule bereits Anmeldungen getätigt hat und wie lange sie bereits Netzwerkschule ist. Wenn alle Plätze vergeben sind, endet die Anmeldung sofort - die angezeigte Anmeldefrist gilt nur, solange noch Plätze zu vergeben sind.
							</Typography>
						</ListItemText>
					</ListItem>
					{ (type === 'basis' || type === 'jury') &&
						<ListItem>
							<ListItemText>
								<Typography variant="body2">
									Außerdem ist es uns wichtig, möglichst viele Projektschulen zu berücksichtigen. Wenn Sie eine größere Gruppe (z. B. eine Fachschaft oder eine Seminargruppe) fortbilden lassen möchten, stellen Sie daher bitte eine <MuiLink
									id="message_Schulung"
									onClick={ () => { setMailtoDialogOpen( !isMailtoDialogOpen ) } }
									underline="hover">individuelle Anfrage</MuiLink>.
								</Typography>
							</ListItemText>
							<MailtoDialog
								disableSubject
								onClose={ () => { setMailtoDialogOpen( !isMailtoDialogOpen ) } }
								open={ isMailtoDialogOpen }
								subject="Anfrage für eine individuelle Schulung"
								title="Anfrage für eine individuelle Schulung"
								to="berg@jugend-debattiert-nrw.de" />
						</ListItem>
					}
					{ type === 'sprachlerngruppe_basis' &&
						<>
							<ListItem>
								<ListItemText>
									<Typography variant="body2">
									Für jede Veranstaltung sind maximal 16 Anmeldungen möglich.
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<Typography variant="body2">
										Es ist uns wichtig, bei der Verteilung der Plätze möglichst viele Projektschulen zu berücksichtigen.
									</Typography>
								</ListItemText>
							</ListItem>
						</>
					}
				</List>
				<Divider />
			</Collapse>
			{ type === 'jury' ?
				<>
					<Typography color="secondary" paragraph variant="body2">
						Teilnehmer:innen dürfen Sie erst anmelden, wenn Ihnen die <b>erforderlichen Einverständniserklärungen ausgefüllt</b> vorliegen.
					</Typography>

					<Typography align="center" color="secondary" gutterBottom variant="h6">Anmeldeverfahren</Typography>
					<Typography paragraph variant="body2">
						Als Schulkoordinator:in können Sie Interessentinnen und Interessenten für die nachfolgend genannten Termine anmelden. Die Anmeldung endet jeweils spätestens <b>drei Tage vor Beginn der Veranstaltung</b>.
					</Typography>
					<Typography paragraph variant="body2">
						Bitte beachten Sie, dass hierbei ein <b>besonderes Anmeldeverfahren</b> eingehalten werden muss. Als Schulkoordinator:in melden Sie Ihre Teilnehmer:innen bitte folgendermaßen an:
					</Typography>
					<Typography color="primary" variant="subtitle1">1. Schritt: Dokumente herunterladen und weitergeben</Typography>
					<Typography>Bitte händigen Sie Interessentinnen und Interessenten zunächst folgende Dokumente aus, die Sie von unserer Homepage herunterladen können:</Typography>
					<Typography variant="body2">
						- die <MuiLink
						href={ `${ process.env.GATSBY_FILES_PDF_ENDPOINT }/Anmeldung_Juryschulungen.pdf` }
						target="_blank">Einladung mit Anmeldeformular <FontAwesomeIcon icon={ faLink } size="sm" /></MuiLink> - <b>nicht</b> erforderlich, wenn <b>ausgebildete Projektlehrer</b> angemeldet werden sollen
					</Typography>
					<Typography paragraph variant="body2">- die <b>Informationen</b> zur jeweiligen Veranstaltung (siehe unten)</Typography>
					<Typography color="primary" variant="subtitle1">2. Schritt: Dokumente einsammeln und Daten eingeben</Typography>
					<Typography variant="body2">
						Nachdem Sie alle Anmeldungen <b>mit allen erforderlichen Unterschriften</b> zurückerhalten haben, geben Sie bitte die Daten der Teilnehmer:innen in das <Link to="anmeldung">Online-Anmeldeformular <FontAwesomeIcon icon={ faLink } size="sm" /></Link> ein.
					</Typography>
					<Typography paragraph variant="body2">Bitte beachten Sie, dass u.a. aufgrund der Vorgaben des Datenschutzes eine <b>Zustimmung der Erziehungsberechtigten strikt erforderlich</b> ist!</Typography>
					<Typography color="primary" variant="subtitle1">3. Schritt: Anmeldungen und Einverständnisse bestätigen</Typography>
					<Typography variant="body2">
						Abschließend müssen Sie <b>bestätigen, dass die angemeldeten Personen die Anmeldungen ausgefüllt einschließlich aller erforderlichen Unterschriften</b> abgegeben haben.
					</Typography>
					<Typography paragraph variant="body2">
						Erst dann können Sie die jeweiligen Daten an uns übermitteln und sehen eine Bestätigungs-Seite, wenn dies erfolgt ist.
					</Typography>
				</>
			:
			<Typography color="secondary" paragraph variant="body2">
				Teilnehmer:innen dürfen Sie erst anmelden, wenn Ihnen die <MuiLink
				href={ `${ process.env.GATSBY_FILES_PDF_ENDPOINT }/Anmeldung_Schulungen_Lehrer.pdf` }
				target="_blank">erforderlichen Einverständniserklärungen <FontAwesomeIcon icon={ faLink } size="sm" /></MuiLink> ausgefüllt vorliegen.
			</Typography>
			}

			<Typography color="secondary" gutterBottom variant="subtitle1">Tipp</Typography>
			<Typography paragraph variant="body2">
				Die { description } finden Sie auch im <Link to={ routes[ 1 ].path }>Terminkalender <FontAwesomeIcon icon={ faLink } size="sm" /></Link>. Jede Veranstaltung können Sie bequem in Ihren eigenen Kalender übernehmen.
			</Typography>

			<Box sx={{ my: 5 }}>
				<Typography align="center" color="secondary" gutterBottom variant="h5">Aktuelle Angebote</Typography>
				<EventsGallery
					EVENTS_QUERY={ GET_CURRENT_EVENTS }
					identifier="currentEvents"
					noEventsMessage="Zur Zeit gibt es keine Veranstaltungen, zu denen Sie sich oder Ihre Kolleginnen bzw. Kollegen anmelden können."
					subheader={ description }
					variables={{ type: type }} />
			</Box>
			<Divider />
			<Box sx={{ my: 5 }}>
				<Typography align="center" color="secondary" gutterBottom variant="h5">Alle Veranstaltungen</Typography>
				<EventsGallery
					EVENTS_QUERY={ GET_EVENTS }
					identifier="eventsWithoutSecondDates"
					noEventsMessage="Es konnten leider keine Veranstaltungen in der Datenbank gefunden werden."
					subheader={ description }
					variables={{ type: type }} />
			</Box>
		</>
	)
}

export default Schulung

export const query = graphql`
query( $id: String! ) {
	eventType( id: { eq: $id } ) {
		description
		information
		type
  	}
}
`
